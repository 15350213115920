/* react-calendar custom styles */
.react-calendar {
	border: 1px solid #e2e8f0;
	border-radius: 8px;
	box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
	padding: 10px;
}

.react-calendar__navigation button {
	color: #e0b960;
	font-weight: bold;
	background: none;
	border: none;
	border-radius: 4px;
	padding: 5px 10px;
	cursor: pointer;
}

.react-calendar__tile {
	border-radius: 6px;
	text-align: center;
	transition: background-color 0.3s ease;
}

.react-calendar__tile--active {
	background-color: #e0b960;
	color: white;
}

.react-calendar__tile:hover {
	background-color: rgba(224, 185, 96, 0.2);
}

.react-calendar__tile--now {
	background-color: rgba(224, 185, 96, 0.1);
}

.react-calendar__month-view__weekdays {
	text-transform: uppercase;
	font-weight: bold;
	color: #333;
}

.react-calendar__month-view__days__day--weekend {
	color: #e0b960;
}
